import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, notification} from "antd";
import Assessment from "./steps/Assessment";
import { createGoalApi } from "../api/index"
import { RootState } from "../redux/store";
import { AssessmentType, resetAssessment } from "../redux/slices/assessSlice";
import { useSummary } from "../utils/hooks/useSummary";

export default function Result(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathway, parentInfo, childInfo } = useSelector<RootState, AssessmentType>(state => state.assessment);
  const [api, contextHolder] = notification.useNotification();

  const [validationText, setValidationText] = useState("")
  const [value, setValue] = useState<string[]>([]);
  const [recommendGoals, assessmentLevel] = useSummary();
  useEffect(() => {
    if(!parentInfo || !pathway) {
      history.push('/parent-form')
      return
    }
  }, []);

  useEffect(() => {
    setValue(recommendGoals.map((one: AIRTABLE_Lookup) => one.lookupRef));
  }, [recommendGoals])

  const nextEvt = () => {
    if(!value.length){
      setValidationText("Please select goal")
      return;
    }

    const goals = recommendGoals.map((one: AIRTABLE_Lookup) => value.includes(one.lookupRef)? { ...one, userSelected: true }: one);
    let data = {
      pathway: pathway?.tableName,
      memberId : childInfo.memberId,
      goals
    }
    
    createGoalApi(data).then(res => {
      dispatch(resetAssessment());
      history.push('/background-info');
      api.success({
        message: "Goals and Skills added."
      })
    }).catch((err)=>{
      console.log(err);
    })
  };

  return (
    <Row gutter={24} className="container-result-main">
      <Col sm={24} md={22} className="side-result-container">
        <Assessment
          assessmentLevel={assessmentLevel}
          validationText={validationText}
          setValidationText={setValidationText}
          value={value}
          setValue={setValue}
          recommendGoals={recommendGoals}
        />
        <Row>
          <Col span={24}>
            <div onClick={nextEvt} className="button-getting-started">
              <button className="getting-start-button">Build Program</button>
            </div>
          </Col>
        </Row>
        { contextHolder }
      </Col>
    </Row>
  );
}
