import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Row, Col } from "antd";
import group108 from "../../images/Group 108.png"
import group109 from "../../images/Group 109.png"
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { AssessmentType } from '../../redux/slices/assessSlice';

function ProceedPage(Props: any) {
  const history = useHistory();
  const { parentInfo, childInfo } = useSelector<RootState, AssessmentType>(state => state.assessment);

  useEffect(() => {
    if(!parentInfo)
      history.push("/result")
  }, [])

  const handleClick = () => {
    // history.push('/qualification')
  }
  
  const contentcard = (id: number, image: any, heading: string, content: string) => {
    return (
      <Row onClick={handleClick} gutter={14} className="row-contentcard-proceed">
        <Col span={6} className="pt-3">
          <img className="proceed-icon" src={image} alt="Loading..." />
        </Col>
        <Col span={18}>
          <div className="heading-proceed">{heading}</div>
          <div className="inner-text-proceed">{content}</div>
        </Col>
      </Row>
    );
  };
  return (
    <div className="container-result-proceed">
      <Col span={24} style={{ padding: "0px" }}>
        <Row>
          <Col span={18}>
            <div className="step-text-main">
              Thanks, lets create a program that is tailored to { childInfo?.childName }’s needs
            </div>
          </Col>
        </Row>
        <div className="content-card-proceed">
          {contentcard(
            1,
            group109,
            "Background Information",
            `We need some more details on ${childInfo?.childName}’s strengths and interets. We’d also like to know what you may have tried in the past and what is working well for him, so far.`
          )}
          {contentcard(
            2,
            group108,
            "On Call Walkthrough",
            "Please schedule a call woth one of our Program advisors who will help optimize the program and discuss pricing"
          )}
        </div>
      </Col>
    </div>
  );
}

export default ProceedPage;
